$(document).ready(function () {
  // inputmask
  $('input[data-mask]').each(function () {
    var $t = $(this), mask = $t.data('mask');
    if (typeof $t.attr('disabled') === 'undefined') {
      $t.inputmask('remove').inputmask('' + mask);
    }
  });

  // фиксим модальное окно через окно
  $(document).on('hidden.bs.modal', function (event) {
    if ($('.modal:visible').length) {
      $('body').addClass('modal-open');
    }
  });

  // message
  window.showBootstrapMessage = function (params) {
    params = $.extend({
      title: '',
      message: ''
    }, params);

    let $v_modal = $('.modal:visible'),
      $m_modal = $('#modal-message'), $title = $m_modal.find('.modal-title'), $message = $m_modal.find('.modal-body');

    $v_modal.modal('hide');
    $('.modal-backdrop').remove();

    $title.html(params.title);
    $message.html('<p>' + params.message + '</p>');

    $m_modal.modal('show');

    if (typeof params['afterHide'] === 'function') {
      $m_modal.on('hidden.bs.modal', function () {
        $m_modal.off('hidden.bs.modal');
        params.afterHide.call();
      });
    }
  };

  // modal
  window.showBootstrapModal = function (selector, params) {
    params = $.extend({
      afterHide: null
    }, params);

    let $v_modal = $('.modal:visible'), $m_modal = $(selector);

    $v_modal.modal('hide');
    $('.modal-backdrop').remove();

    $m_modal.modal('show');

    if (typeof params['afterHide'] === 'function') {
      $m_modal.on('hidden.bs.modal', function () {
        $m_modal.off('hidden.bs.modal');
        params.afterHide.call();
      });
    }
  };
});
